import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ContactComponent } from './contact/contact.component';
import { WorksListComponent } from './works-list/works-list.component';
import { WorksDetailsComponent } from './works-details/works-details.component';


const routes: Routes = [
  { path: '',  component: HomepageComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'realisations', component: WorksListComponent},
  { path: 'work/:id', component: WorksDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
