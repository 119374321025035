import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-works-details',
  templateUrl: './works-details.component.html',
  styleUrls: ['./works-details.component.scss']
})
export class WorksDetailsComponent implements OnInit {
  works: Entry<any>;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		const worksId = this.route.snapshot.paramMap.get('id');
		this.contentfulService.getDetail(locale, 'works', worksId)
			.then((works) => {
				this.works = works;
				// console.log(this.works);
			});
			
		this.title.setTitle('Présentation | Voxels Productions');
    this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
	}
	goToList() {
		this.router.navigate(['/works']);
	}

}



