import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html'
})
export class HomepageComponent implements OnInit {
	homepage: Entry<any>;
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		const homepageId = '4ybKKyy7OMQHnVI4EusifU';
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		this.contentfulService.getPage(locale, 'voxelsHomepage', homepageId)
			.then((homepage) => {
				this.homepage = homepage;
				console.log(this.homepage);
			});
			
		this.title.setTitle('Présentation | Voxels Productions');
    this.meta.updateTag({ name: 'description', content: 'Voxels Productions' });
	}

	htmlToRichText(richText) {
		if (richText === undefined || richText === null || richText.nodeType !== 'document') {
		  return '<p>Error</p>';
		}
		return documentToHtmlString(richText);
	}

}
