import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ContentfulService } from './contentful.service';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SafePipePipe } from './safe-pipe.pipe';
import { WorksDetailsComponent } from './works-details/works-details.component';
import { WorksListComponent } from './works-list/works-list.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MdToHtmlPipe } from './md-to-html.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image'; 

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    SafePipePipe,
    WorksDetailsComponent,
    WorksListComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    MdToHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    FontAwesomeModule,
    LazyLoadImageModule
  ],
  providers: [
    ContentfulService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }