<div class="container" *ngIf="contact">
  <section class="row">
    <div class="col-sm-12">
      <h1>Contacts</h1>
    </div>
  </section>
  <section class="row padding-bottom">
    <div class="col-sm-6">
      <h3>{{ contact.fields.typeTitle }}</h3>
      <div class="rte" [innerHTML]="htmlToRichText(contact.fields.mainAddress)"></div>
    </div>
    <div class="col-sm-6 contact-image">
      <img src="{{ contact.fields.mainImage.fields.file.url }}" />
    </div>
  </section>
</div>
