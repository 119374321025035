import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-works-list',
  templateUrl: './works-list.component.html',
  styleUrls: ['./works-list.component.scss']
})
export class WorksListComponent implements OnInit {
	defaultImage = '../../assets/img/lazyload.png';
	settings: Entry<any>; 
  works: Entry<any>[] = [];
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
    private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		this.contentfulService.getListWork(locale, 'works', 1000)
			.then(works => this.works = works);

			$('.filter-button-group').on( 'click', 'div', function() {
				var filterValue = $(this).attr('data-filter');
				if(filterValue === "all"){
					$('.filter-items').fadeIn();
					$('.filter-button-group div').removeClass('is-checked');
					$(this).addClass('is-checked');
				}else{
					$('.filter-items').fadeOut();
					$(filterValue).fadeIn();
					$('.filter-button-group div').removeClass('is-checked');
					$(this).addClass('is-checked');
				}
			});

		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			console.log(this.settings);
		});

		this.title.setTitle('Présentation | Voxels Productions');
    this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
	}
	htmlToRichText(richText) {
		if (richText === undefined || richText === null || richText.nodeType !== 'document') {
		  return '<p>Error</p>';
		}
		return documentToHtmlString(richText);
	}

}
