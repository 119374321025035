<footer class="footer container-fluid">
    <div class="bg-footer">
        <div class="region region-footer">
            <section id="block-mc-front-pages-block-footer" class="block block-mc-front-pages clearfix">


                <div class="row content-bottom">
                    <div class="col-sm-12">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">
                                © Voxels Production 2020 </font>
                        </font>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </section>
        </div>
    </div>
</footer>