import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private title: Title,
		private meta: Meta
		
	) { }

  ngOnInit() {
		this.title.setTitle('Voxels Productions');
		this.meta.updateTag({ name: 'description', content: 'Voxels Productions' });
	}
}
