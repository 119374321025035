<header id="navbar" role="banner" class="navbar container navbar-default">
    <div class="row" id="navbar">
        <div class="col-sm-12 col-md-7">
            <a href="index.html" id="logo-title2"><img src="../../assets/img/logo_voxel.png" alt="Home"></a>
        </div>
        <div class="col-md-5 social">
            <div class="link-social">
                <a href="https://vimeo.com/channels/992590" target="_blank"><i class="fab fa-vimeo"></i></a>
                <a href="https://www.facebook.com/voxelsproductions/?fref=ts" target="_blank"><i class="fab fa-facebook-square"></i></a>
            </div>
        </div>
    </div>
    <div id="menu-icon"(click)="openMenu()"><span class="c-hamburger"><span></span></span></div>
</header>
<div class="container-fluid navbar links">
    <div class="container" style="position: relative">
        <ul id="main-menu" *ngIf="settings">
            <li class="main-menu-item"><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="closeMenu()" routerLink="/">{{ settings.fields.voxelsLinksPresentations }} <span class="slash">&nbsp;&nbsp;/
                        &nbsp;</span></a></li>
            <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()" routerLink="/realisations/">{{ settings.fields.voxelsLinksRealisations }} <span class="slash">&nbsp;&nbsp;/
                        &nbsp;</span></a></li>
            <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()" routerLink="/contact/">Contacts</a></li>
        </ul>
        <div #lang id="lang">
            <a data-lang="en-US" [ngClass]="{'active': locale == 'en-US'}" (click)="langSelect(langEn.getAttribute('data-lang'))" #langEn>En </a> / 
            <a data-lang="fr" [ngClass]="{'active': locale == 'fr'}" (click)="langSelect(langFr.getAttribute('data-lang'))" #langFr> Fr</a> 
        </div>
    </div>

</div>