<div class="container">
  <section class="row">
    <div class="col-sm-12">
      <br><br>
      <div class="filter-button-group works">
        <div data-filter="all" class="is-checked">-<a *ngIf="settings">{{ settings.fields.workcatAll }}</a>-</div>
        <div data-filter=".other-natures"><a *ngIf="settings">{{ settings.fields.workcatOther }}</a></div>
        <div data-filter=".digital-arabesques"><a *ngIf="settings">{{ settings.fields.workcatDigital }}</a></div>
        <div data-filter=".streams-and-networks"><a *ngIf="settings">{{ settings.fields.workcatStreams }}</a></div>
        <div data-filter=".virtual-cities"><a *ngIf="settings">{{ settings.fields.workcatVirtual }}</a></div>
      </div>
    </div>
  </section>
  <section class="row padding-bottom">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 works-cc filter-items {{ work.fields.workCat}}" *ngFor="let work of works">
      <a routerLink="/work/{{ work.fields.slug }}"><img [defaultImage]="defaultImage" [lazyLoad]="work.fields.mainImage.fields.file.url" /></a>
      <a routerLink="/work/{{ work.fields.slug }}">
        <h5>{{ work.fields.title }}</h5>
      </a>
      <a routerLink="/work/{{ work.fields.slug }}">
        <p>{{ work.fields.location }}</p>
      </a>
    </div>
  </section>
</div>



<!-- use https://isotope.metafizzy.co/ OR https://vestride.github.io/Shuffle/ for content filtering -->
