<div class="container" *ngIf="homepage">
  <section class="row">
    <div class="col-sm-12">
      <h1>{{ homepage.fields.title }}</h1>
    </div>
  </section>
  <section class="row padding-bottom">
    <div class="col-sm-12">
      <div class="rte" [innerHTML]="htmlToRichText(homepage.fields.introContent)"></div>
      <br><br>
      <div class="videoWrapper">
        <iframe [src]="homepage.fields.videoLink | safePipe " width="100%" height="380" frameborder="0" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen=""></iframe>
          <br><br>
      </div>
    </div>
    <div class="col-sm-12 rte presentation" [innerHTML]="htmlToRichText(homepage.fields.mainContent)"></div>
  </section>
</div>
