import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  contact: Entry<any>;
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		const contactId = '6iH0hGUDKwdSwj0B1CGo29';
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		this.contentfulService.getPage(locale, 'contactPage', contactId)
			.then((contact) => {
				this.contact = contact;
				console.log(this.contact);
			});
			
		this.title.setTitle('Contact | Voxels Productions');
    this.meta.updateTag({ name: 'description', content: 'Voxels Productions' });
	}

	htmlToRichText(richText) {
		if (richText === undefined || richText === null || richText.nodeType !== 'document') {
		  return '<p>Error</p>';
		}
		return documentToHtmlString(richText);
	}

}
